import { graphql, useStaticQuery } from "gatsby";
import React from 'react'

import Hero from "../components/healthtech-page/hero/Hero.js";
import Layout from '../components/layout/Layout'
import Seo from '../components/seo'

const HealthtechPage = (props) => {
    const {strapiHealthtech} = useStaticQuery(query);

    return (
        <Layout path={props.location.pathname}>
            <Seo
                title={strapiHealthtech.metaTitle}
                description={strapiHealthtech.metaDescription}
                keywords={strapiHealthtech.metaKeywords}
            />
            <Hero/>
        </Layout>
    )
}

export default HealthtechPage

const query = graphql`
    query {
        strapiHealthtech {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`
